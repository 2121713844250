<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-model="query.cardId" clearable filterable placeholder="请选择品牌" class="mr10"
					@change="getCardId" style="width: 140px;">
					<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
						:value="item.label"></el-option>
				</el-select>
				<el-select size="small" v-model="query.type" clearable filterable placeholder="请选择类型" class="mr10"
					style="width: 140px;">
					<el-option v-for="item in typeList" :key="item.label" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.city" clearable filterable placeholder="请选择城市" class="mr10 mt10"
					style="width: 160px;" v-if="query.type==2"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="getData"
					style="margin-top: 10px;">搜索</el-button>
				<el-button size="small" type="primary" style="margin-top: 10px;" :class="[theme]"
					@click="getAdd">添加</el-button>
			</div>

			<div style="width: 100%;height: 20px;clear: both;"></div>
			<el-row :gutter="20" type="flex" class="row-bg">
				<el-col :span="24">
					<el-table :data="tableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
						@selection-change="handleSelectionChange" :row-class-name="tableRowClassName"
						@row-click="showExpressDialog">
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column prop="cardName" label="品牌" align="center"></el-table-column>
						<el-table-column prop="city" label="城市" align="center"></el-table-column>
						<el-table-column prop="type" label="类型" align="center"></el-table-column>
						<el-table-column prop="num" label="预警数量" align="center"></el-table-column>
						<el-table-column label="操作" align="center">
							<template #default="scope">
								<el-button size="small" type="text" icon="el-icon-edit"
									@click="handleEdit(scope.row)">编辑</el-button>
								<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
									@click="handleDelete(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 100, 200, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<el-dialog title="添加" v-model="addVisible" width="450px" :close-on-click-modal="false">
			<el-form :model="addForm" ref="addForm" label-width="120px">
				<el-form-item label="类型">
					<el-radio-group v-model="addForm.type">
						<el-radio label="1">基础预警</el-radio>
						<el-radio label="2">指定城市预警</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="城市" v-if="addForm.type==2">
					<el-input size="small" type="text" placeholder="请输入城市" v-model="addForm.city"></el-input>
				</el-form-item>
				<el-form-item label="品牌">
					<el-select size="small" v-model="addForm.cardId" clearable filterable placeholder="请选择"
						style="width: 100%;">
						<el-option v-for="item in cardTypeList" :key="item.value" :label="item.value"
							:value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="预警数量">
					<el-input size="small" type="text" placeholder="请输入预警数量" v-model="addForm.num"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="addClose">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getForewarningAdd">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="编辑" v-model="editVisible" width="450px" :close-on-click-modal="false">
			<el-form :model="editForm" ref="editForm" label-width="120px">

				<el-form-item label="预警数量">
					<el-input size="small" type="text" placeholder="请输入预警数量" v-model="editForm.num"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="addClose">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getForewarningUpdate">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		forewarningList,
		forewarningAdd,
		forewarningDelete,
		forewarningUpdate
	} from '../api/SjtxCard.js';
	import { getCardType } from '../api/cardType.js';
	export default {
		name: 'earlyWarning',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					cardId: '',
					type: '',
					city: '',
					pageIndex: 1,
					pageSize: 30,
				},
				tableData: [],
				pageTotal: 0,
				multipleSelection: [],
				typeList: [{
						value: '1',
						label: '基础预警',
					},
					{
						value: '2',
						label: '指定城市',
					},
				],
				cardTypeList: [],
				addForm: {
					type: '1',
					cardId: '',
					city: '',
					num: '',
				},
				editForm: {
					num: '',
					id: '',
				},
				uploadData: {
					userId: localStorage.getItem('user')
				},
				path: process.env.BASE_URL,
				addVisible: false,
				editVisible: false,
				token: {
					Authorization: localStorage.getItem('token'),
				},
			};
		},
		created() {
			this.getCardType();
			this.getData();
		},
		methods: {
			//获取品牌和品牌里面的套餐
			getCardType(item) {
				getCardType('').then(res => {
					this.cardTypeList = res.data;
				})

			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getData() {
				forewarningList(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//添加
			getAdd() {
				this.addVisible = true;
				this.addForm.type = '1';
				this.addForm.cardId = '';
				this.addForm.city = '';
				this.addForm.num = '';
			},
			getForewarningAdd() {
				forewarningAdd(this.addForm).then(res => {
					if (res.code == 200) {
						this.addVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},

			//关闭出库弹窗
			addClose() {
				this.addVisible = false;
			},
			//关闭出库弹窗
			editClose() {
				this.editVisible = false;
			},
			//编辑
			handleEdit(row) {
				this.editVisible = true;
				this.editForm.num = row.num;
				this.editForm.id = row.id;
			},
			getForewarningUpdate() {
				forewarningUpdate(this.editForm).then(res => {
					if (res.code == 200) {
						this.editVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleDelete(row) {
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: row.id
						}
						forewarningDelete(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			//下一页
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			//获取库存城市列表
			getSJSZInventoryStatistics() {
				this.query.city = '';
				let data = {
					province: this.query.province,
					city: this.query.city,
					standard: this.query.standard,
					cardId: this.query.cardId,
					setMealId: this.query.setMealId,
				};
				InventoryByProvince(data).then(res => {
					if (res.code == 200) {
						this.cityList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},


		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 50px;
	}

	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mt10 {
		margin-top: 10px;
	}
</style>
import request from '../utils/request';
import qs from "qs";

// Sjtx
// export const setMealList = id => {
// 	return request.get('/api/setMeal/list')
// };
// Sjtx库存添加
export const setMealAdd = param => {
	return request.post('/api/SjtxCard/setMealAdd', qs.stringify(param))
};
// Sjtx库存套餐列表
export const setMealList = param => {
	return request.post('/api/SjtxCard/setMealList', qs.stringify(param))
};
// Sjtx库存修改
export const setMealUpdate = param => {
	return request.post('/api/SjtxCard/setMealUpdate', qs.stringify(param))
};
// Sjtx库存删除
export const setMealDelete = param => {
	return request.post('/api/SjtxCard/setMealDelete', qs.stringify(param))
};
// Sjtx库存列表
export const InventoryCardList = param => {
	return request.post('/api/SjtxCard/InventoryCardList', qs.stringify(param))
};
// Sjtx库存添加
export const getImport = param => {
	return request.post('/api/SjtxCard/import', qs.stringify(param))
};
// Sjtx库存出库
export const delivery = param => {
	return request.post('/api/SjtxCard/delivery', qs.stringify(param))
};
// Sjtx库存城市
export const InventoryByProvince = param => {
	return request.post('/api/SjtxCard/InventoryByProvince', qs.stringify(param))
};
// Sjtx库存省市
export const InventoryProvinceCity = param => {
	return request.post('/api/SjtxCard/InventoryProvinceCity', qs.stringify(param))
};
// Sjtx库存省
export const InventoryProvince = param => {
	return request.post('/api/SjtxCard/InventoryProvince', qs.stringify(param))
};
// Sjtx库存市
export const InventoryCity = param => {
	return request.post('/api/SjtxCard/InventoryCity', qs.stringify(param))
};
// Sjtx库存 新库存
export const InventoryInfo = param => {
	return request.post('/api/SjtxCard/InventoryInfo', qs.stringify(param))
};
// Sjtx库存  出库记录
export const deliveryList = param => {
	return request.post('/api/SjtxCard/deliveryList', qs.stringify(param))
};
// Sjtx库存  物流信息
export const logisticsInquiry = param => {
	return request.post('/api/SjtxCard/logisticsInquiry', qs.stringify(param))
};
// Sjtx库存  历史地址
export const companyAddress = param => {
	return request.post('/api/SjtxCard/companyAddress', qs.stringify(param))
};
// Sjtx库存  操作人
export const deliveryOperator = param => {
	return request.post('/api/SjtxCard/deliveryOperator', qs.stringify(param))
};
// Sjtx库存  城市
export const deliveryCity = param => {
	return request.post('/api/SjtxCard/deliveryCity', qs.stringify(param))
};
// Sjtx库存  预警列表
export const forewarningList = param => {
	return request.post('/api/SjtxCard/forewarningList', qs.stringify(param))
};
// Sjtx库存  预警添加
export const forewarningAdd = param => {
	return request.post('/api/SjtxCard/forewarningAdd', qs.stringify(param))
};
// Sjtx库存  预警修改
export const forewarningUpdate = param => {
	return request.post('/api/SjtxCard/forewarningUpdate', qs.stringify(param))
};
// Sjtx库存  预警删除
export const forewarningDelete = param => {
	return request.post('/api/SjtxCard/forewarningDelete', qs.stringify(param))
};
// Sjtx库存  预警数据
export const QueryForewarning = param => {
	return request.post('/api/SjtxCard/QueryForewarning', qs.stringify(param))
};
// Sjtx库存  预警数据导出
export const downloadForewarning = param => {
	return request.post('/api/SjtxCard/downloadForewarning', qs.stringify(param), {
		responseType: 'blob'
	})
};